import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames';

import logo from '../images/notthinkablelogo.png'

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    const { open } = this.state;
    const hamburgerClasses = classNames({
      'hamburger': true,
      'hamburger-clicked': open,
    });
    const brandClasses = classNames({
      'brand': true,
      'brand-with-shadow': !open,
    })

    return <div>
      <div className={brandClasses}>
        <div className="brand-container">
          <Link
            className="logo-link"
            to={'/'}>
              <img alt="logo" className="logo-image" src={logo}/>
              <h3 className="logo-text">Not Thinkable</h3>
          </Link>
          <div role="menu" className="hamburger-menu"
            onKeyDown={() => this.setState({ open: !open })}
            onClick={() => this.setState({ open: !open })}
            tabIndex={0}
          >
            <nav className={hamburgerClasses}>
              <div className="hamburger-line-1"></div>
              <div className="hamburger-line-2"></div>
              <div className="hamburger-line-3"></div>
            </nav>
          </div>
          <div className="navigation-menu">
            <Link className="navigation-menu-item" to="/about">
              About
            </Link>
            <Link className="navigation-menu-item" to="/blog">
              All posts
            </Link>
            {/*<Link className="navigation-menu-item" to="/speaking">
              Speaking
            </Link>*/}
            <a className="navigation-menu-item" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCwPfNgDSxeqEFvWNYhw_LSw">
              YouTube <span role="img" aria-label="Links To Youtube" className="menu-arrow">→</span>
            </a>
          </div>
        </div>
      </div>
      {
        open && <div className="slider-menu">
          <Link className="slider-menu-item" to="/about">
            About
          </Link>
          <Link className="slider-menu-item" to="/blog">
            All posts
          </Link>
          {/*<Link className="slider-menu-item" to="/speaking">
            Speaking
          </Link>*/}
          <a rel="noopener noreferrer" className="slider-menu-item" target="_blank" href="https://www.youtube.com/channel/UCwPfNgDSxeqEFvWNYhw_LSw">
            YouTube <span role="img" aria-label="Links To Youtube" className="menu-arrow">→</span>
          </a>
        </div>
      }
    </div>
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
